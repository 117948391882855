@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


input {
  --tw-ring-shadow: 0 0 #000 !important;
  outline: none !important;
}
textarea {
  --tw-ring-shadow: 0 0 #000 !important;
}

/* Custom Checkbox Styles */
input[type="checkbox"] {
  /* Remove default styles */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Set dimensions */
  width: 14px;
  height: 14px;

  /* Add black border */
  border: 1px solid rgba(95, 99, 104, 1);
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  position: relative;
  background-color: white; /* Default background */
  transition: all 0.3s ease-in-out;
}

/* Checked state */
input[type="checkbox"]:checked {
  background-color: rgb(247, 84, 116); /* Custom checked color */
  border-color: rgb(247, 84, 116);
}

/* Custom SVG checkmark when checked */
input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"%3E%3Cpolyline points="20 6 9 17 4 12"/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-size: contain;
}

/* Label styling */
label {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: black;
  cursor: pointer;
}